import React, { useState } from 'react'
import Expand from 'react-expand-animated';

const Accordion = (props) => {
  const { children, summary, className, subClassname, open, link, lists } = props
  const [expand, setExpand] = useState(open)

  return (
    <div className={`${className} pl-4 md:px-4 items-center `}>
      <div role="button" tabIndex={0} className={`flex justify-between items-center ${subClassname}`} onClick={() => { if (!open) setExpand(!expand) }} onKeyDown={() => { if (!open) setExpand(!expand) }}>
        <div className='faq-subject text-white py-2 px-4 w-full cursor-pointer items-center justify-between flex'>
          <p className="sm:text-xl">{summary}</p>
          {!open && <img src="/icons/ic_next.png" alt='expand' className={` transform ${expand ? "rotate-90" : "rotate-0"} duration-500 h-4 flex-shrink-0`} />}
        </div>
      </div>
      <Expand open={expand} className='rounded-b-3xl border-b-4 border-l-4 border-r-4 border-app-purple font-gothic-italic font-bold'>
        <p className={`px-4 break-words ${lists !== "" ? "pt-2" : "py-2"}`}>
          {children}
          {link !== "" && <a href={link} target="_blank" rel="noreferrer" style={{ color: "blue" }}> {link}</a>}
        </p>
        {lists !== "" && (
          <ul className='px-4 pb-2'>
            {lists.map((list, idx) => (<li key={idx}>{list}</li>))}
          </ul>
        )}
      </Expand>
    </div>
  )
}

Accordion.defaultProps = {
  open: false
}

export default Accordion

export const FaqAccordion = React.memo((props) => {
  const { children, summary, className, subClassname, selected, setSelected, index } = props

  return (
    <div className={`${className} items-center `}>
      <div role="button" tabIndex={0} className={`faq-subject justify-between flex items-center border-2 border-blue-700 py-2 px-4 cursor-pointer ${subClassname}`} onClick={() => { setSelected(index) }} onKeyDown={() => { setSelected(index) }}>
        <p className="text-white text-2xl lg:text-3xl">{summary}</p>
        {index === selected && <img src="/icons/ic_selected.svg" alt="selected" className="h-6" />}
      </div>
      <Expand open={index === selected} className=''>
          {children}
      </Expand>
    </div>
  )
})