export const homeData = [
  {
      img: "/home_page/icon_rune.png",
      background: "purple",
      content: "WL RUNE TOKENS - AIRDROPPED FOR FREE!",
      width: "w-3/4"
  },
  {
      img: "/home_page/icon_250.png",
      background: "blue",
      content: "250,000,000 END will be awarded for season 1",
      width: "w-4/5"
  },
  {
      img: "/home_page/icon_sneak.png",
      background: "green",
      content: "sneak peeks,games and more in our discord",
      width: "w-3/4"
  },
];

export const playData = [
  {
    step: "01",
    class: "2xl:container",
    content: "Choose a faction & Mint your warrior",
    desktop: "/home_page/play_desk_step1.png",
    mobile: "/home_page/play_mobile_step1.png",
  },
  {
    step: "02",
    class: "2xl:container",
    content: "round 1 matchmaking begins 24hrs post-sale",
    desktop: "/home_page/play_desk_step2.png",
    mobile: "/home_page/play_mobile_step2.png",
  },
  {
    step: "03",
    class: "",
    content: "view your 'fight card' featuring your opponent and countdown to battle",
    desktop: "/home_page/play_desk_step3.png",
    mobile: "/home_page/play_mobile_step3.png",
  },
  {
    step: "04",
    class: "2xl:container",
    content: "48hrs post-matchmaking, Round 1 modifiers are calculated and battle results determined - 100% provably random & on-chain.",
    desktop: "/home_page/play_desk_step4.png",
    mobile: "/home_page/play_mobile_step4.png",
  },
  {
    step: "05",
    class: "2xl:container",
    content: "END is issued (80% to winners, 20% to losers after Round 1) and defeated warriors gain entry to Valhalla",
    desktop: "/home_page/play_desk_step5.png",
    mobile: "/home_page/play_mobile_step5.png",
  },
  {
    step: "06",
    class: "2xl:container",
    content: "Matchmaking begins for the next round and players may opt to sacrifice their potential 'loser payout' by praying to their God, in exchange for a special blessing in their upcoming battle.",
    desktop: "/home_page/play_desk_step6.png",
    mobile: "/home_page/play_mobile_step6.png",
  }
];

export const factionList = [
  {
    name: "ODIN",
    img: "/home_page/faction_1.png"
  },
  {
    name: "THOR",
    img: "/home_page/faction_2.png"
  },
  {
    name: "FENRIR",
    img: "/home_page/faction_3.png"
  },
  {
    name: "HEL",
    img: "/home_page/faction_4.png"
  },
  {
    name: "JORMUNGAND",
    img: "/home_page/faction_5.png"
  },
  {
    name: "LOKI",
    img: "/home_page/faction_6.png"
  },
  {
    name: "SURTR",
    img: "/home_page/faction_7.png"
  },
  {
    name: "YMIR",
    img: "/home_page/faction_8.png"
  },
];

export const roadmap = [
  {
    id: "01",
    title: "Meet The Factions",
    content: "Once sale has completed, a total of 4,096 warriors will be deployed into battle, each representing one of the eight main factions (512 per faction).",
    img: "/home_page/roadmap_1.png"
  },
  {
    id: "02",
    title: "Tournament Bracket Creation",
    content: "Each warrior will be randomly paired against each other, with each warrior having an initial 50% chance of winning the match; a percentage that is modified by their rarity tier and potentially the relative strength of their faction to their opponents.",
    img: "/home_page/roadmap_2.png"
  },
  {
    id: "03",
    title: "Liquidity Pool Locked",
    content: "$200,000 USD has been raised through private offering and 100% will be immediately deployed to the liquidity pool. The LP token will then be burned. ",
    img: "/home_page/roadmap_3.png"
  },
  {
    id: "04",
    title: "The Battles Begin",
    content: "It’s time for war! Your warrior will pair off for its first battle with the winner receiving 80% of the prize pool in END and the defeated warrior collecting 20%. Each round will take place every 48 hours until there is only one warrior left!",
    img: "/home_page/roadmap_4.png"
  },
]

export const faqList = [
  {
    subject: "END OF DAYS",
    faq: [
      {
        question: "end of days? explain.",
        answer: "End of Days is the brand itself. Each season will have its own unique identity, such as Season One: Ragnarok, but the brand is what ties all the seasons together under a single umbrella."
      },
      {
        question: "where did the name come from?",
        answer: "While every season will have its own theme, what they all have in common is the aspect of a cataclysmic battle or final battle of some sort. We thought this was a great name that embraced that idea."
      }
    ]
  },
  {
    subject: "END TOKEN",
    faq: [
      {
        question: "What is END?",
        answer: "END is the main currency for Ragnarok. END is available on uniswap/sushiswap and original liquidity of $200,000 was provided by our private investors."
      },
      {
        question: "What can I use END on?",
        answer: "END can be used to mint new warriors at a price of 75,000 END or reincarnation if your warrior has been defeated for the next season at a cost of 50,000 END."
      },
      {
        question: "Where will the END be supplied from for future seasons?",
        answer: "100% of proceeds from seasons 2-8 NFTs will be placed back into the ecosystem based off the following breakdown:",
        lists: ["50% - Prize Pool", "40% - Staking Rewards", "10% - Night of Champions Prize Pool"]
      }
    ]
  },
  {
    subject: "Season 1 - Mint",
    faq: [
      {
        question: "When does mint start for Whitelist holders?",
        answer: "Presale date will be announced ASAP once we see current market conditions improve.",
      },
      {
        question: "When does mint start for Public?",
        answer: "Public Sale will begin 24 hours after Presale does and run until sold out.",
      },
      {
        question: "What happens if I hold a Free Mint token?",
        answer: "Sit back and relax! We will handle burning your token and airdropping you a random warrior from the faction it represents."
      },
      {
        question: "What happens to my Whitelist token if I choose not to mint?",
        answer: "All unused tokens will be burnt once the Presale period closes, to avoid secondary sales of a useless item."
      }
    ]
  },
  {
    subject: "Season 1 - Details",
    faq: [
      {
        question: "Who is the main artist for Season One?",
        answer: "RIOTG3AR is the main artist, you can view his Twitter here:",
        link: "https://twitter.com/riotg3ar"
      },
      {
        question: "Who is the WL Token artist for Season One?",
        answer: "V.VIZ is the WL 'Rune' token artist, you can view his Twitter here:",
        link: "https://twitter.com/v_viz_"
      },
      {
        question: "What are the 4 rarity tiers and boost percentages?",
        answer: "Common (Drengr): 0, Uncommon (Thegn): +2.5%, Epic (Berserkr): +10% and Legendary (Einherjar): +25%."
      },
      {
        question: "Can I sell my warrior during the tournament or is it locked until the end?",
        answer: "You can sell your warrior at any time, just be sure to claim any END in your account before you do."
      },
      {
        question: "What happens to my warrior if he is defeated?",
        answer: "Your warrior is no longer eligible to continue in battle, however, you may use END to reincarnate for a discounted price of 50,000 END which grants you 1 free mint in the next season."
      }
    ]
  },
  {
    subject: "Night of Champions",
    faq: [
      {
        question: "Who qualifies for the Night of Champions?",
        answer: "All warriors sharing a faction with the winner of Seasons 1-8 will automatically be entered in the Night of Champions tournament, with guaranteed prizes for every participant. The NoC will take place shortly after the conclusion of Season 8."
      },
      {
        question: "How much is the prize pool for the Night of Champions?",
        answer: "10% of all end collected from mint or reincarnation fees in Seasons 1-8 will make up the NoC prize pool."
      },
      {
        question: "Do I have to pay any fees or gas to enter if my Warrior is eligible?",
        answer: "No! There will be no out of pocket expense to have your warrior entered and you are guaranteed to leave with a prize!"
      }
    ]
  },
];

export const teamData = [
  {
    img: "/home_page/team_keith.png",
    name: "KEITH BUSSEY",
    role: "MASTERBREWS-VARIUS",
    content: `An experienced techpreneur with over 20 years in the online industry, Keith’s strengths lie in his resourcefulness, 
              creativity and work ethic. As an aggressive CTO who delivers results, he has helped build, scale and guide companies in commerce (over $1B in sales), dating and mobile gaming - just to name a few.`,
    twitter: "https://twitter.com/MasterBrewsNFT",
    linkedin: "https://www.linkedin.com/in/varius/"
  },
  {
    img: "/home_page/team_adam.png",
    name: "ADAM HUDANI",
    role: "TILTING-SHOCK",
    content: `Adam has held numerous senior level positions such as Chief Operating fficer, VP of Ops and Head of Operations for 
              companies across numerous verticals such as i-gaming, ecommerce and social gaming. Adam has helped numerous companies grow from start- up status to industry leaders enerating over $300M in revenue.`,
    twitter: "https://twitter.com/TiltingS",
    linkedin: "https://www.linkedin.com/in/adam-hudani/"
  },
  {
    img: "/home_page/team_jason.png",
    name: "JASON SILVERT",
    role: "THEPENGUINVA",
    content: `Better known as ThePenguinVA, Jason specializes incustomerrelations management and acts as acommunity manager and 
              occasional client liaison for MasterBrews. Prior to MasterBrews, Jason spent a decade as tech support with Apple. Previously Jason spent 10 years as a manager with Blockbuster Video. Jason has over 20 years of customer satisfaction experience.`,
    twitter: "https://twitter.com/ThePenguinVA",
    linkedin: "https://www.linkedin.com/in/jason-silvert-6b623679"
  },
  {
    img: "/home_page/team_medina.png",
    name: "j.Medina",
    role: "JPMM21",
    content: `Medina AKA jpmm21 is a young Cuban doctor who after fighting Covid-19 during 2021, was reborn as Web3 enthusiast. 
              Medina now acts as Community Manager for MasterBrews.`,
    twitter: "https://twitter.com/jpmm_21",
    linkedin: "https://www.linkedin.com/in/juan-pablo-medina-mulet-90114b11a"
  },
  {
    img: "/home_page/team_alin.png",
    name: "alin popa",
    role: "DASKING",
    content: `Alin is a senior full-stack software engineer, teaching assistant, and graduate researcher. Passionate about computer science 
              and innovation, Alin has been consistently delivering great software solutions.Alin is currently pursuing a Ph.D. in Financial Machine Learning, and has multiple IEEE-Indexed research articles on cryptocurrency and neural networks.`,
    twitter: "#",
    linkedin: "https://www.linkedin.com/in/alinn-popa/"
  },
  {
    img: "/home_page/team_riot.png",
    name: "riotg3ar",
    role: "S1 ARTIST",
    content: `Dennis Mabuka is a 3d artist from Nairobi, Kenya. His work has been featured as a finalist in the largest car rendering challenge in the industry. He loves to work with music, credited with projects for west african artist Shatta Wale. 
              His work loves to explore the mystical boundary between fantasy and sci-fi from the perspective of a mind developed in the east coast of Africa in the 20th & 21st century.`,
    twitter: "https://twitter.com/riotg3ar",
    linkedin: "#"
  },
  {
    img: "/home_page/team_tim.png",
    name: "timothy jooste",
    role: "ADVISOR",
    content: `Tim has spent most of his successful career building a running large Sales organizations for mid size to Fortune 500 companies. 
              He has a personally developed 100s of leaders and helped recruit teams of 500+ sale people producing 100-200 million per year in tech sales. Tim specializes in cultivating an amazing work environment and culture as well as finding and developing leaders. Tim began his career as a door 2 door salesman and has risen to the top of each company he has worked for while also winning numerous national awards including the nationally recognized Gold Stevie Award forSales Director of the Year in the United States!`,
    twitter: "https://twitter.com/TimothyJooste",
    linkedin: "https://www.linkedin.com/in/timothy-jooste-8594a491/"
  },
  {
    img: "/home_page/team_mascot.png",
    name: "Bullseye",
    role: "S1 MASCOT",
    content: `Lover of bones, drinker of mead, breaker of hearts. Unbeknown to many, it was Bullseye who first taught the All-Father his fabled Odinsleep. The only creature to have both melted Ymir's icy heart, and cooled Surtr's fiery rage, Bullseye counts Huginn and Muninn amongst his closest pals. Bullseye keeps the team in line and morale high!`,
    twitter: "#",
    linkedin: "#"
  }
];
