import React from 'react'
import Layout from '../components/layout/layout'
import HomePage from '../components/partials/HomePage'


const Raganrok = () =>{
  return(
    <Layout page="home">
      <HomePage />
    </Layout>
  )
}

export default Raganrok