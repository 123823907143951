import React from 'react';

export const SectionTitle = (props) =>{
  const {children, className} = props

  return(
    <h2 className={`${className} uppercase text-4xl lg:text-6xl pt-12 pb-8`}>{children}</h2>
  )
}

SectionTitle.defaultProps={
  className:"text-center"
}

export const SectionH3 = (props) =>{
  const {children, className} = props

  return(
    <h3 className={`${className} text-white uppercase text-md md:text-2xl lg:text-4xl xl:text-5xl`}>{children}</h3>
  )
}