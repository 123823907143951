import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Accordion, { FaqAccordion } from "../../accordion";
import { SectionTitle } from "../../label";
import { homeData, faqList, teamData, playData } from "./data";
import "./homepage.scss";

const HomeBox = () => {
  return (
    <div className="top-header h-screen relative">
      <div className="hidden lg:block absolute top-28 2xl:top-32 left-1/2 transform -translate-x-1/2 w-2/3 container text-center">
        <img src="/home_page/top_logo_1.png" alt="top-logo" className="w-2/3 mx-auto" />
        <p className="uppercase text-white text-3xl 2xl:text-4xl home-title">
          Automated On-Chain Battle Tournament
        </p>
      </div>
      <div className="hidden lg:block absolute bottom-6 2xl:bottom-12 w-full mt-12">
        <div className="2xl:container lg:w-11/12 mx-auto">
          <div className="grid grid-cols-3 gap-12 2xl:gap-20 2xl:-mx-12">
            {homeData.map((item, idx) => {
              return (
                <div className={`grid-item relative bg-image bg-${item.background} px-4 pt-26 2xl:pt-40 pb-4 2xl:pb-6 text-white`} key={idx}>
                  <div className={`team-item-img absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${item.width}`}>
                    <img src={item.img} className="w-full" alt="team img" />
                  </div>
                  <p className="text-2xl 2xl:text-3xl text-center">
                    {item.content}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <img src="/home_page/top_logo_vertical_1.png" alt="top-logo" className="lg:hidden absolute top-14 mt-9 tiny:mt-10 right-5 h-2/3 md:h-3/4" />
      <div className="lg:hidden absolute w-full bottom-5 tiny:bottom-6">
        <div className="md:w-2/3 mx-auto text-center">
          <p className="uppercase text-white text-2xl tiny:text-3xl md:text-4xl home-title px-10">
            Automated On-Chain Battle Tournament
          </p>
          <div className="flex items-center justify-center rounded-3xl w-48 sm:w-52 bg-blue-500 text-white uppercase py-1 tiny:py-2 px-2 tiny:px-3 mx-auto mt-3 tiny:mt-4 cursor-pointer">
            <img src="/home_page/icon_arrow_bottom.png" alt="icon arrow" className="w-5 sm:w-6 mr-3" />
            <div className="text-lg sm:text-xl">
            <ScrollLink
              to="play"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Discover More
            </ScrollLink>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HomeItemsBox = () => {
  return (
    <div className="lg:hidden homeItems-box pt-12 pb-20 px-6">
      <div className="grid grid-cols-1 tiny:grid-cols-2 gap-10">
        {homeData.map((item, idx) => {
          return (
            <div className={`grid-item relative bg-image bg-${item.background} px-4 pt-26 tiny:pt-20 sm:pt-26 pb-4 mt-20 border-${item.background} rounded-lg text-white`} key={idx}>
              <div className={`team-item-img absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${item.width}`}>
                <img src={item.img} className="w-full" alt="team img" />
              </div>
              <p className="text-2xl text-center">
                {item.content}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const PlayBox = () => {
  return (
    <div className="play play-box bg-black pt-4 pb-12">
      <SectionTitle className="text-white text-center">How To Play</SectionTitle>
      {playData.map((item, idx) => {
        return (
          <div key={idx} className={`${item.class} mx-auto ${idx !== 2 ? "px-6 md:px-10" : "px-2 sm:px-0"} ${idx > 3 ? "sm:hidden" : ""}`}>
            <div className={`flex text-white 2xl:container mx-auto ${idx === 0 ? "mt-0 md:mt-16" : "mt-16"} pb-8 ${idx === 2 ? "px-6 md:px-10" : ""}`}>
              <div className="flex flex-col items-center mr-6">
                <p className="text-xl 2xl:text-2xl">
                  STEP
                </p>
                <p className="text-5xl 2xl:text-6xl">
                  {item.step}
                </p>
              </div>
              <p className="text-3xl 2xl:text-4xl flex flex-col justify-end">
                {item.content}
              </p>
            </div>
            <img src={item.desktop} alt="play img" className="hidden sm:block mx-auto" />
            <img src={item.mobile} alt="play img" className="sm:hidden mx-auto" />
          </div>
        )
      })}
      <div className="hidden sm:grid grid-cols-2 gap-6 2xl:container mx-auto px-10">
        {playData.map((item, idx) => {
          return idx > 3 && (
            <div key={idx} className="grid-item">
              <div className="flex text-white mt-20 pb-8">
                <div className="flex flex-col mr-6">
                  <p className="text-xl">
                    STEP
                  </p>
                  <p className="text-5xl">
                    {item.step}
                  </p>
                </div>
                <p className="text-3xl flex flex-col justify-end">
                  {item.content}
                </p>
              </div>
              <img src={item.desktop} alt="play img" className="hidden lg:block mx-auto w-4/5" />
              <img src={item.mobile} alt="play img" className="lg:hidden mx-auto" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const TeamBox = () => {
  return (
    <div className="teams">
      <div className="container flex flex-col m-auto px-10 small:px-20 sm:px-10">
        <SectionTitle className="text-black text-center">MEET OUR TEAM</SectionTitle>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-10 pb-5 pt-10 mt-10 m-auto z-0 ">
          {teamData.map((dt, idx) => (
            <div className="grid-item text-center team-panel relative pt-20 mb-24 xl:mb-36 text-white" key={idx}>
              <div className="team-item-img absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3 p-2 w-1/2">
                <img src={dt.img} className="w-full" alt="team img" />
              </div>
              <div className="pb-20 px-4 h-full bg-team-panel pt-6 xl:pt-10">
                <p className="w-full text-2xl xl:text-3xl pt-3 small:pt-6 sm:pt-3 md:pt-6 lg:pt-2 xl:pt-3 2xl:pt-6">
                  {dt.name}
                </p>
                <p className="text-black-light mb-3 xl:text-lg font-gothic-bold text-gray-300">
                  {dt.role}
                </p>
                <p className="font-gothic-italic text-gray-200">
                  {dt.content}
                </p>
                <div className="flex gap-5 justify-center items-center absolute bottom-6 left-1/2 transform -translate-x-1/2">
                  {dt.twitter !== "#" && (
                    <a href={dt.twitter} target="_blank" rel="noreferrer">
                      <img src="/icons/ic_twitter.png" alt="icon twitter" className="w-8"></img>
                    </a>
                  )}
                  {dt.linkedin !== "#" && (
                    <a href={dt.linkedin} target="_blank" rel="noreferrer">
                      <img src="/icons/ic_linkedin.png" alt="icon linkedin" className="w-8"></img>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-10 divider absolute"></div>
      </div>
    </div>
  )
}

const FaqBox = () => {
  const [selected, setSelected] = useState(0)

  return (
    <div className="faq pb-10">
      <SectionTitle className="text-white text-center">faq</SectionTitle>
      <div className="md:flex w-full md:w-10/12 lg:9/12 xl:w-8/12 mx-auto gap-4 md:flex-row flex-col container hidden">
        <div className="w-4/12 gap-4 md:flex flex-col hidden">
          {faqList.map((faq, idx) => (
            <div key={idx} role="button" tabIndex={idx} className="faq-subject justify-between flex items-center border-2 border-blue-700 py-2 px-4 cursor-pointer" onClick={() => { setSelected(idx) }} onKeyDown={() => { setSelected(idx) }}>
              <p className="text-white text-2xl lg:text-3xl">{faq.subject}</p>
              {idx === selected && <img src="/icons/ic_selected.svg" alt="selected" className="h-6" />}
            </div>
          ))}
        </div>
        <div className="flex md:hidden">
          <Accordion summary={faqList[selected].faq[0].question} open={true} className="w-1/2" link={faqList[selected].faq[0].link ? faqList[selected].faq[0].link : ""} lists="">
            {faqList[selected].faq[0].answer}
          </Accordion>
          <div className="w-1/2 gap-4 flex flex-col">
            {faqList.map((faq, idx) => (
              <div key={idx} role="button" tabIndex={idx} className="faq-subject justify-between flex items-center border-2 border-blue-700 py-2 px-4 cursor-pointer" onClick={() => { setSelected(idx) }} onKeyDown={() => { setSelected(idx) }}>
                <p className="text-white text-xl sm:text-2xl">{faq.subject}</p>
                {idx === selected && <img src="/icons/ic_selected.svg" alt="selected" className="h-6 lg:h-8" />}
              </div>
            ))}
          </div>
        </div>
        <div className="w-8/12 gap-2 flex-col md:flex hidden">
          {
            faqList[selected].faq.map((faq, idx) => (
              <Accordion summary={faq.question} key={idx} open={idx === 0} link={faq.link ? faq.link : ""} lists={faq.lists ? faq.lists : ""}>
                {faq.answer}
              </Accordion>
            ))
          }
        </div>
        <div className="w-full gap-2 flex-col md:hidden flex">
          {
            faqList[selected].faq.map((faq, idx) => (
              idx > 0 && <Accordion summary={faq.question} key={idx} link={faq.link ? faq.link : ""} lists={faq.lists ? faq.lists : ""}>{faq.answer}</Accordion>
            ))
          }
        </div>
      </div>
      <div className="flex w-full md:hidden flex-col gap-4 px-4">
        {faqList.map((faq, idx) => (
          <FaqAccordion summary={faq.subject} key={idx} selected={selected} setSelected={setSelected} index={idx}>
            {faq.faq.map((faq, index) => (
              <Accordion summary={faq.question} key={index} link={faq.link ? faq.link : ""} lists={faq.lists ? faq.lists : ""} className="mt-2">
                {faq.answer}
              </Accordion>
            ))}
          </FaqAccordion>
        ))}
      </div>
    </div>
  )
}

const HomePage = () => {
  return (
    <div className="home-container">
      <HomeBox />
      <HomeItemsBox />
      <PlayBox />
      <TeamBox />
      <FaqBox />
    </div>
  );
};

export default HomePage;
